import { SubDomains } from '@/constants/subdomains';

interface HiddenDomainConfig {
  slug: SubDomains;
  shouldRedirectAnonymous: boolean;
}

const HIDDEN_DOMAINS_CONFIG: HiddenDomainConfig[] = [
  {
    slug: SubDomains.uk,
    shouldRedirectAnonymous: true,
  },
];

export const isKnowelyDomain = (domain: string): boolean => (
  domain === SubDomains.us
);

// NOTE: If you see this, please ask @ivadimko "Why GM?"
export const i18nIsGMDomain = (domain: string): boolean => (
  HIDDEN_DOMAINS_CONFIG.some(({ slug }) => slug === domain)
);

export const i18nShouldRedirectAnonymous = (domain: string): boolean => (
  HIDDEN_DOMAINS_CONFIG.some(({ slug, shouldRedirectAnonymous }) => (
    slug === domain && shouldRedirectAnonymous
  ))
);
